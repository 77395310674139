import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import axios from "axios";

import { SectionTitle, Checkbox, HelpText } from "../Section";
import PrimaryButton from "../Button/PrimaryButton";
import LineArrowRight from "../Icons/LineArrowRight";

const FormWrap = styled.div`
  position: relative;
  padding: 20px 30px 30px 30px;
  background: ${(props) => props.bgColor};
  border-radius: 6px;

  form {
    width: 100%;

    p {
      margin-bottom: 15px;
    }

    .two-col {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: 48%;
        padding: 10px 20px 12px;
        border: 1px solid #d0d0d0;
        border-radius: 6px;
        margin-bottom: 15px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
      }
    }
    textarea {
      width: 100%;
      padding: 10px 20px 12px;
      border: 1px solid #d0d0d0;
      border-radius: 6px;
      margin-bottom: 15px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
      resize: none;
    }
  }
  @media (max-width: 480px) {
    form {
      .two-col {
        display: block;

        input {
          width: 100%;
          padding: 10px;
        }

        textarea {
          padding: 10px;
        }
      }
    }
  }
  @media (max-width: 400px) {
    padding: 25px 10px;

    form {
      p {
        margin-bottom: 10px;
      }
      .two-col {
        input {
          margin-bottom: 10px;
        }
      }
      textarea {
        margin-bottom: 0;
      }
    }
  }
`;

class QuoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      email: "",
      phone: "",
      zipcode: "",
      comment: "",
      agreeViaSms: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let comment = document.getElementById("comment");
    comment.addEventListener("input", function (e) {
      comment.setCustomValidity(""); //remove message when new text is input
    });
    comment.addEventListener("invalid", function (e) {
      comment.setCustomValidity("Please enter your full namewefwefwefw"); //custom validation message for invalid text
    });
  }

  handleChange(event) {
    const { id, value } = event.target;
    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      });
      if (id === "comment") {
        if (!value || value.trim() === "") {
          event.target.setCustomValidity("Please fill out this field.");
        } else {
          event.target.setCustomValidity("");
        }
      }
    } else {
      this.setState({
        [id]: event.target.checked,
      });
    }
  }
  handleSubmit(event) {
    event.preventDefault();

    const { location } = this.props;
    const data = {
      source: location.origin,
      form: "Quote Form",
      page: location.pathname,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone,
      zipcode: this.state.zipcode,
      comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
      agreeViaSms: this.state.agreeViaSms,
    };
    //console.log("==========", data)
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then((res) => {
        this.setState({
          full_name: "",
          email: "",
          phone: "",
          zipcode: "",
          comment: "",
          agreeViaSms: false,
        });
        navigate("/thank-you-get-a-quote");
      })
      .catch((error) => {
        alert(error);
      });
  }
  render() {
    const { background } = this.props;
    return (
      <FormWrap bgColor={background ? background : "#F7F5F5"}>
        <form id="QuoteForm" onSubmit={this.handleSubmit}>
          <SectionTitle textAlign="left" mb="0">
            Get A Quote
          </SectionTitle>
          <p>
            We not only fulfill your metal building needs, but we also make
            dreams come true.
          </p>
          <div className="two-col">
            <input
              type="text"
              name="full_name"
              id="full_name"
              onChange={this.handleChange}
              placeholder="Full Name"
              pattern="^(?!\s*$)[A-Za-z\s]+$"
              required
            />
            <input
              type="email"
              name="email"
              id="email"
              onChange={this.handleChange}
              placeholder="Email Id"
              required
            />
          </div>
          <div className="two-col">
            <input
              type="tel"
              name="phone"
              id="phone"
              onChange={this.handleChange}
              placeholder="Contact Number"
              pattern="[0-9]{10}"
              required
            />
            <input
              type="text"
              name="zipcode"
              id="zipcode"
              onChange={this.handleChange}
              pattern="^[0-9]{5}$"
              placeholder="Zip Code"
              required
            />
          </div>
          <div className="one-col">
            <textarea
              name="comment"
              placeholder="comment"
              onChange={this.handleChange}
              id="comment"
              rows="4"
              required
            />
          </div>
          <div className="one-col">
            <Checkbox>
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  id="agreeViaSms"
                  name="agreeViaSms"
                  defaultChecked={true}
                  onChange={this.handleChange}
                  aria-label="contacted via SMS"
                />
                {` `}I agree that I will be contacted via SMS.
              </label>
            </Checkbox>
            <HelpText>
              By submitting this form, I agree to receive text messages from
              Buildings And More. Standard messaging rates and data charges may
              apply. Message frequency may vary. You can opt-out by replying
              STOP at any time or reply Help to get more information.
            </HelpText>
          </div>

          <button type="submit">
            <PrimaryButton text="Submit" icon={<LineArrowRight />} />
          </button>
        </form>
      </FormWrap>
    );
  }
}

export default QuoteForm;
